
const digest = '2eeec81c955f09068fdb150bf8d8fdd15a18a9900681046d7803b6c647bd9e63';
const css = `._timesheetTableActions_hd6fx_1 {
  display: flex;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"timesheetTableActions":"_timesheetTableActions_hd6fx_1"};
export { css, digest };
  